//Imports
import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { getCookie } from "../Hooks/Cookie";
import Loader from "../Fragments/Loader";

/**
 * Daily Stream desktop
 * authentication page component
 */
const DesktopAuth = () => {
  //States
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(true);

  //Helpers
  /**
   * Executes after component
   * has rendered
   */
  useEffect(() => {
    // Check for required cookies
    const genUserId = getCookie("genUserId");

    if (!genUserId) {
      // If not authenticated, redirect to login
      navigate("/login?returnUrl=/desktop-authentication");
      return;
    }

    // Collect all cookie data
    const cookieData = {
      genUserId,
      userId: getCookie("userId"),
      roomId: getCookie("roomId"),
      width: getCookie("width"),
      height: getCookie("height"),
      frameRate: getCookie("frameRate"),
      sampleSize: getCookie("sampleSize"),
      sampleRate: getCookie("sampleRate"),
      audioBitsPerSecond: getCookie("audioBitsPerSecond"),
      videoBitsPerSecond: getCookie("videoBitsPerSecond"),
      companyId: getCookie("companyId"),
      authLevel: getCookie("authLevel"),
      monitors: getCookie("monitors"),
      url: getCookie("url"),
      channel: getCookie("channel"),
    };

    // Base64 encode the cookie data
    const encodedData = btoa(JSON.stringify(cookieData));

    // Redirect to desktop app with data
    window.location.href = `streamsight://${encodedData}`;

    setIsProcessing(false);
  }, [navigate]);

  return isProcessing ? (
    <Loader />
  ) : (
    <div className="page-wrapper">
      <div className="page-content">
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ minHeight: "50vh" }}
        >
          <h4>Processing authentication request...</h4>
        </div>
      </div>
    </div>
  );
};

//Exports
export default DesktopAuth;
