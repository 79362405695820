//Imports
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faTimesCircle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { setCookie, getCookie } from "../../Hooks/Cookie";
import "../../Assets/css/modal.css";

//Icon Styles
const styles = {
  colors: {
    primary: {
      color: "#007bff",
    },
  },
  hidden: {
    display: "none",
  },
  visible: {
    display: "table-cell",
  },
  font: {
    fontFamily: "Roboto, Helvetica, sans-serif",
  },
  table: {
    maxHeight: "400px !important",
  },
  card: {
    header: {
      backgroundColor: "#727cf5",
      color: "#fff",
    },
  },
  icons: {
    connected: {
      color: "#10b759",
    },
    disconnected: {
      color: "#ff3366",
    },
    save: {
      color: "#10b759",
      fontSize: "1.2rem",
      cursor: "pointer",
      marginRight: "10px",
    },
    cancel: {
      color: "#fbbc06",
      fontSize: "1.2rem",
      cursor: "pointer",
    },
    edit: {
      color: "#007bff",
      fontSize: "1.2rem",
      cursor: "pointer",
    },
    danger: {
      color: "#ff3366",
      fontSize: "1.2rem",
      cursor: "pointer",
    },
  },
  alignMiddle: {
    alignItems: "center",
  },
  spinner: {
    height: "5rem",
    width: "5rem",
  },
};

/**
 * Daily Stream twofactor
 * authentication form
 * for twofactor page
 */
const TwofacForm = () => {
  //States
  const [twofacData, setTwofacData] = useState({
    genUserId: getCookie("genUserId"),
    userId: getCookie("userId"),
    code: "",
  });
  const [modal, setModal] = useState({
    title: "",
    body: "",
    submit: false,
    submitText: "",
    submitEvent: null,
    close: false,
    closeText: "",
  });
  const [showModal, setShowModal] = useState(false);

  //Helpers
  /**
   * Handles opening and
   * closing the modal
   */
  const handleShowModal = () => setShowModal(!showModal);

  /**
   * Submit twofac code
   * data to API
   */
  const sendCode = (e) => {
    //Prevent refresh
    e.preventDefault();

    if (twofacData.code.length > 0) {
      //Verifying code modal
      setModal({
        title: "Twofactor",
        body: (
          <>
            <Row className="d-flex align-items-center justify-content-center text-center mb-3">
              <Col>
                <FontAwesomeIcon
                  size="2x"
                  style={styles.icons.disconnected}
                  icon={faSpinner}
                  spin
                />
              </Col>
            </Row>
            <Row className="d-flex align-items-center justify-content-center text-center mb-auto">
              <Col>
                <p className="mb-auto">Verifying twofactor code...</p>
              </Col>
            </Row>
          </>
        ),
        submit: false,
        close: false,
        hideEvent: false,
      });
      handleShowModal();

      //Emit data
      fetch(`${window.apiSite}/code`, {
        method: "POST",
        credentials: `include`,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: twofacData.genUserId + twofacData.userId,
          password: twofacData.code,
        }),
      }).then(async (res) => {
        if (!res.ok) {
          console.error(res);
          onCode(false);
        } else {
          onCode(true);
        }
      });
    } else {
      //Display error message
      setModal({
        title: "Twofactor",
        body: (
          <>
            <Row className="d-flex align-items-center justify-content-center text-center mb-3">
              <Col>
                <FontAwesomeIcon
                  size="2x"
                  style={styles.icons.disconnected}
                  icon={faTimesCircle}
                />
              </Col>
            </Row>
            <Row className="d-flex align-items-center justify-content-center text-center mb-auto">
              <Col>
                <p className="mb-auto">
                  You must enter in a twofactor authentication code!
                </p>
              </Col>
            </Row>
          </>
        ),
        submit: false,
        close: false,
        hideEvent: false,
      });
      handleShowModal();
    }
  };

  /**
   * Login callback for twofactor
   * authentication response
   * @param {JSON} data response data
   *  @param {String} data.genUserId generic user id for account
   *  @param {Boolean} data.idleTracking idle detection is enabled
   *  @param {Number} data.idleTrackingAuthLevel max auth level of idle detection if enabled
   *  @param {Number} data.roomId users id
   *  @param {String} data.width recording width
   *  @param {String} data.height recording height
   *  @param {Number} data.frameRate recording framerate
   *  @param {Number} data.sampleSize recording sample size
   *  @param {Number} data.sampleRate recording sample rate
   *  @param {Number} data.audioBitsPerSecond recording audio bits per second
   *  @param {Number} data.videoBitsPerSecond recording video bits per second
   *  @param {Number} data.companyId user's company id
   *  @param {Number} data.authLevel user's auth level
   *  @param {Number} data.usingOneTimePassword user is using a one time password
   *  @param {Number} data.hasResetPassword user has reset password
   *  @param {Number} data.monitors number of monitors user records
   *  @param {String} data.url dailystream app url for user
   *  @param {Number} data.channel user's channel
   *  @param {String} data.message error message for request
   */
  const onCode = (data) => {
    if (data) {
      setModal({
        title: "Success",
        body: (
          <>
            <Row className="d-flex align-items-center justify-content-center text-center mb-3">
              <Col>
                <FontAwesomeIcon
                  size="2x"
                  style={styles.icons.connected}
                  icon={faCheckCircle}
                />
              </Col>
            </Row>
            <Row className="d-flex align-items-center justify-content-center text-center mb-auto">
              <Col>
                <p className="mb-auto">
                  Successfully authenticated login request. Logging in...
                </p>
              </Col>
            </Row>
          </>
        ),
        submit: false,
        close: false,
        hideEvent: false,
      });
      handleShowModal();

      setTimeout(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const returnUrl = urlParams.get("returnUrl");

        if (returnUrl && returnUrl.includes("desktop-authentication")) {
          // For desktop authentication, redirect directly to the desktop-authentication page
          window.location.replace("/desktop-authentication");
        } else if (returnUrl) {
          window.location.href = decodeURIComponent(returnUrl);
        } else {
          window.location.href = `${getCookie("url")}/channels`;
        }
      }, 2000);
    } else {
      //Clear cookie data
      setCookie("genUserId", null, -1);
      setCookie("roomId", null, -1);
      setCookie("width", null, -1);
      setCookie("height", null, -1);
      setCookie("frameRate", null, -1);
      setCookie("sampleSize", null, -1);
      setCookie("sampleRate", null, -1);
      setCookie("audioBitsPerSecond", null, -1);
      setCookie("videoBitsPerSecond", null, -1);
      setCookie("companyId", null, -1);
      setCookie("authLevel", null, -1);
      setCookie("monitors", null, -1);
      setCookie("url", null, -1);
      setCookie("channel", null, -1);

      //Display error message
      let defaultMsg = "Invalid code. Please check your code and try again.";
      let msg = data.message || defaultMsg;
      setModal({
        title: "Error",
        body: (
          <>
            <Row className="d-flex align-items-center justify-content-center text-center mb-3">
              <Col>
                <FontAwesomeIcon
                  size="2x"
                  style={styles.icons.disconnected}
                  icon={faTimesCircle}
                />
              </Col>
            </Row>
            <Row className="d-flex align-items-center justify-content-center text-center mb-auto">
              <Col>
                <p className="mb-auto">{msg}</p>
              </Col>
            </Row>
          </>
        ),
        submit: false,
        close: false,
        hideEvent: false,
      });
      handleShowModal();
    }
  };

  /**
   * Sets twofactor code
   */
  const setCode = (e) => {
    setTwofacData((prevData) => ({
      genUserId: prevData.genUserId,
      userId: prevData.userId,
      code: e.target.value,
    }));
  };

  /**
   * Emails twofactor code
   * to email address on account
   */
  const emailCode = () => {
    //Emailing code modal
    setModal({
      title: "Emailing",
      body: (
        <>
          <Row className="d-flex align-items-center justify-content-center text-center mb-3">
            <Col>
              <FontAwesomeIcon
                size="2x"
                style={styles.icons.disconnected}
                icon={faSpinner}
                spin
              />
            </Col>
          </Row>
          <Row className="d-flex align-items-center justify-content-center text-center mb-auto">
            <Col>
              <p className="mb-auto">Emailing authentication code...</p>
            </Col>
          </Row>
        </>
      ),
      submit: false,
      close: false,
      hideEvent: false,
    });
    handleShowModal();

    //Emit email code data
    let data = {
      genUserId: getCookie("genUserId"),
      userId: twofacData.userId,
    };
    fetch(`${window.apiSite}/emailCode`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then(async (res) => {
      if (!res.ok) {
        console.error(res);
        onEmailCode({ code: 0 });
      } else {
        let json = await res.json();
        onEmailCode(json);
      }
    });
  };

  /**
   * Email code callback
   * for handling response
   * @param {JSON} data response data
   *  @param {Number} data.code status of email code request
   *  @param {String} data.message error message for request
   */
  const onEmailCode = (data) => {
    //Check email code status
    if (data.code) {
      //Display success message
      setModal({
        title: "Success",
        body: (
          <>
            <Row className="d-flex align-items-center justify-content-center text-center mb-3">
              <Col>
                <FontAwesomeIcon
                  size="2x"
                  style={styles.icons.connected}
                  icon={faCheckCircle}
                />
              </Col>
            </Row>
            <Row className="d-flex align-items-center justify-content-center text-center mb-auto">
              <Col>
                <p className="mb-auto">
                  Successfully emailed authentication code!
                </p>
              </Col>
            </Row>
          </>
        ),
        submit: false,
        close: false,
        hideEvent: false,
      });
      handleShowModal();
    } else {
      //Display error message
      let defaultMsg = "We were unable to email your code";
      let msg = data.message || defaultMsg;
      setModal({
        title: "Error",
        body: (
          <>
            <Row className="d-flex align-items-center justify-content-center text-center mb-3">
              <Col>
                <FontAwesomeIcon
                  size="2x"
                  style={styles.icons.disconnected}
                  icon={faTimesCircle}
                />
              </Col>
            </Row>
            <Row className="d-flex align-items-center justify-content-center text-center mb-auto">
              <Col>
                <p className="mb-auto">{msg}</p>
              </Col>
            </Row>
          </>
        ),
        submit: false,
        close: false,
        hideEvent: false,
      });
      handleShowModal();
    }
  };

  /**
   * Executes after component
   * has rendered
   */
  useEffect(() => {}, []);

  return (
    <section id="twofac-section" className="m-8">
      {/* Modal Window */}
      <Modal
        show={showModal}
        onHide={handleShowModal}
        size="sm"
        dialogClassName="alert-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{modal.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modal.body}</Modal.Body>
        {modal.submit || modal.close ? (
          <Modal.Footer>
            {modal.submit ? (
              <Button
                variant="primary"
                onClick={
                  modal.submitEvent ? modal.submitEvent : handleShowModal
                }
              >
                {modal.submitText}
              </Button>
            ) : null}
            {modal.close ? (
              <Button variant="danger" onClick={handleShowModal}>
                {modal.closeText}
              </Button>
            ) : null}
          </Modal.Footer>
        ) : null}
      </Modal>

      <Container className="p-5">
        <Row className="justify-content-center">
          <Col lg={5}>
            <Col>
              <h2 className="text-center mb-3">Twofactor Code</h2>
              <Form>
                <Form.Label>Code</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="XXXXXX"
                    required="required"
                    data-error="Twofactor Code is required."
                    onChange={setCode}
                  />
                </InputGroup>
                <Row>
                  <Col>
                    <Button
                      type="submit"
                      className="btn-block"
                      onClick={(e) => sendCode(e)}
                    >
                      Submit Code
                    </Button>
                  </Col>
                </Row>
              </Form>
              <small style={{ cursor: "pointer" }} onClick={emailCode}>
                Send Code via Email?
              </small>
            </Col>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

//Exports
export default TwofacForm;
